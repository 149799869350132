@import '../components/custom/assets/scss/Creative.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #D7E8FF;
}

::-webkit-scrollbar-thumb {
    background-color: #879099;
    border-radius: 10px;
    cursor: pointer;
}

.modal-body-scrollbar {
    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.4rem;
    }

    &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #D7E8FF;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #879099;
        border-radius: 10px;
        cursor: pointer;
    }
}

.blue-box {
    background-color: #159CB6;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pink-box {
    background-color: #EE2D66;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.green-box {
    background-color: #2DB693;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.yellow-box {
    background-color: #F8B017;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.veeramangudi {
    background-color: #348BFB;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pattukottai {
    background-color: #45E393;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.papanasam {
    background-color: #FCAF3B;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ariyalur {
    background-color: #FE4A65;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.kumbakonam {
    background-color: #765ACB;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

// .card {
//     box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
//     ;
// }

// .btn {
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
// }

.cursor-no-drop {
    cursor: no-drop;
}

.table-wrapper {
    overflow: auto !important;
    max-height: calc(100vh - 200px);
}

.required:after {
    content: " *";
    color: red;

}

// .table > :not(caption) > * > * {
//     padding: 0.85rem 0.85rem;
//     background-color: var(--bs-table-bg);
//     border-bottom-width: 1px;
//     box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
// }
.table> :not(caption)>*>* {
    padding: 6px;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

table.colored {
    border-collapse: collapse;
    width: 100%;
    border: solid 1px #7B98D3;
    color: black;
}

table.colored th,
td {
    text-align: left;
    padding: 8px;
}

table.colored tr:nth-child(even) {
    background-color: #FFFFFF;
}

table.colored tr:nth-child(odd) {
    background-color: #D1DBEF;
}

table.colored th {
    background-color: #365BB7;
    color: white;
}

table.colored tr {
    border: none;
}

.white {
    color: white;
}

.black {
    color: black;
}