//
// quilljs-editor.scss
//
 
.react-draft-wrapper {
    border: 1px solid $input-border-color !important;
    border-radius: $border-radius;
    .draft-toolbar {
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
        border-bottom: 1px solid $input-border-color;
        border-radius: 0;
        margin-bottom: 0;
        background: transparent;

        .rdw-option-wrapper,
        .rdw-dropdown-wrapper {
            border: none !important;
            box-shadow: none !important;
        }
        .rdw-dropdown-optionwrapper {
            .rdw-dropdownoption-active, .rdw-dropdownoption-highlighted {
                background: transparent !important;
            }
        }
    }
    .rdw-editor-main {
        padding: $input-padding-y $input-padding-x !important;
        .public-DraftStyleDefault-ul {
            padding-left: 1.5em;
            .public-DraftStyleDefault-block {
                margin: 0 !important;
            }
        }
    }
}

